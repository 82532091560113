
import { BsYoutube} from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";
import { pageCollection } from "../Page/PageCollection";

function Footer() {

  
  return (
    <div className=" px-5 sm:py-10 sm:pb-40 py-5  bg-white rounded border-t border-gray-300">
      <div className="flex mx-auto max-w-7xl">
        <ul className="w-full flex mb-5 justify-between">
          <li className="space-x-3 flex">
            <a href="https://www.instagram.com/changggo_/" target="_blank" className="text-2xl text-gray-700 transition hover:text-black">
              <AiFillInstagram/>
            </a>
            <a href="https://www.youtube.com/channel/UCuaRx-4wUbMuCSkA6jX4tmA" target="_blank" className="text-2xl text-gray-700 transition hover:text-black">
              <BsYoutube/>
            </a>
          </li>
        </ul>
      </div>
      <div className="relative flex items-center justify-between mx-auto max-w-7xl">
        <ul className="text-gray-600">
          <li className="mb-2 sm:flex">
            <p className="mb-1"><span>(주)창꼬</span></p>
            <p className="sm:ml-3 mb-1"><span>대표 : 이창섭</span></p>
            <p className="sm:ml-3 mb-1"><span>경기도 수원시 팔달구 덕영대로 895</span></p>
            <p className="sm:ml-3 mb-1"><span>전화번호 : 070-7782-0309</span></p>
            <p className="sm:ml-3 mb-1"><span>사업자등록번호 : 221-87-02867</span></p>
          </li>
          <li className="mb-2 sm:flex">
            <p className="mb-1"><span>통신판매업신고 : 2023-수원팔달-0368</span></p>
          </li>
          
          <li className="flex flex-row gap-2 mb-2">
            <Link to={pageCollection.etcPages[0].url}
              className ={"hover:text-black font-bold transition"}> 
              {pageCollection.etcPages[0].name}
            </Link>
            <Link to={pageCollection.etcPages[1].url}
              className ={"hover:text-black hover:font-bold transition"}> 
              {pageCollection.etcPages[1].name}
            </Link>
          </li>
          <li>
            © 2023 창꼬. All Rights Reserved.
          </li>
        </ul>
      </div>
    </div> 
  );
}
  
  export default Footer;
  

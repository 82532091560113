import { useState } from "react"
import { useEffect } from "react"
import { customAxios } from "../../Components/Common/customAxios"


const Test = () => {


  const [start,setStart]= useState(false)

  useEffect(()=>{

    setStart(true)

  },[])

  useEffect(()=>{

    const getTest = async() =>{
      const result = await customAxios.get("/api/getMessage2")
      console.log(result.data)
      
    }

    if(start){
      getTest()
    }

  },[start])

  return (
    <div>
        <h2> Multiple items </h2>
     
      </div>
  )
}
export default Test

import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';

import Top_BG from '../../Images/Class/bg.jpg';
import Bottm_BG from '../../Images/Class/bg2.jpg';
import Bg_mo from '../../Images/Class/bg_mo.jpg';
import Bg_mo2 from '../../Images/Class/bg2_mo.jpg';

import { useEffect, useState } from 'react';

import Trainer01 from '../../Images/Class/TrainerDetail01_.jpg';
import Trainer02 from '../../Images/Class/TrainerDetail02.jpg';
import Trainer03 from '../../Images/Class/TrainerDetail03.jpg';
import Trainer04 from '../../Images/Class/TrainerDetail04.jpg';
import Trainer05 from '../../Images/Class/TrainerDetail05.jpg';
import Trainer06 from '../../Images/Class/TrainerDetail06.jpg';
import Trainer07 from '../../Images/Class/TrainerDetail07.jpg';
import Trainer08 from '../../Images/Class/TrainerDetail08.jpg';
import Trainer09 from '../../Images/Class/TrainerDetail09.jpg';
import Trainer10 from '../../Images/Class/TrainerDetail10.jpg';
import Trainer11 from '../../Images/Class/TrainerDetail11.jpg';
import Trainer12 from '../../Images/Class/TrainerDetail12.jpg';
import Trainer13 from '../../Images/Class/TrainerDetail13.png';
import Trainer14 from '../../Images/Class/TrainerDetail14.jpg';
import Trainer15 from '../../Images/Class/TrainerDetail15.jpg';
import Trainer16 from '../../Images/Class/TrainerDetail16.jpg';



function Class() {

  const [startTimer, setStartTimer] = useState(0);
  const [days, setDays] = useState(parseInt(0));
  const [hours, setHours] = useState(parseInt(0));
  const [minutes, setMinutes] = useState(parseInt(0));
  const [seconds, setSeconds] = useState(parseInt(0));  

  const timeFunc = () =>{

    let CurDate = new Date();
    let LimitDate = new Date("2023/01/09 23:59:59");
    let CountDate = LimitDate-CurDate;
    
    if(CountDate <=0){

      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    }
    
    const diffDay = Math.floor(CountDate / (1000*60*60*24));
    const diffHour = Math.floor((CountDate / (1000*60*60)) % 24);
    const diffMin = Math.floor((CountDate / (1000*60)) % 60);
    const diffSec = Math.floor(CountDate / 1000 % 60);

    setDays(diffDay);
    setHours(diffHour);
    setMinutes(diffMin);
    setSeconds(diffSec);

  }

  useEffect(() => {
    setStartTimer(1);

  },[])
  useEffect(() => {
    if(startTimer ===1){
      timeFunc();
    }
  },[startTimer])

  useEffect(() => {
    const countdown = setInterval(() => {
      timeFunc();
    }, 1000);
    return () => clearInterval(countdown);
  }, [seconds]);
  
  //Accordion
  const [open, setOpen] = useState(0);
 
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  }
  //Accordion End
    return (
      <div className='break-keep'>
          <Header />
        <div className='camp_wrap'>
          <section className='text-white relative before'>
            <article className='mx-auto sm:h-screen max-w-7xl sm:py-32 pt-32 pb-28 px-5 relative z-1 flex items-center'>
              <div className=''>
                <h1 className='text-2xl sm:text-4xl sm:leading-normal font-bold leading-normal w-full'>
                  <span className='text-xl block text-Cgcolor'>창꼬실용음악학원</span>
                </h1>  
                <h2 className='text-xl sm:text-2xl sm:leading-normal font-bold leading-normal w-full my-10'>
                  <span className='block'>당신의 목표를 이루기 위해 창꼬가 함께합니다.</span>
                </h2>  
                <h3 className='text-sm sm:text-xl sm:leading-normal leading-normal w-full'>
                  <span className='block opacity-70'>창꼬의 전문가들과 함께, 실현 가능한 목표로 만드세요.</span>
                </h3>  
              </div>  
            </article>
            <img  src={Top_BG} className='sm:h-screen absolute inset-0 w-full opacity-0 sm:opacity-100' alt='Bg1' /> 
            <img  src={Bg_mo} className='absolute inset-0 w-full opacity-100 sm:opacity-0' alt='Bg1_1' /> 
          </section>
          <section className='text-white relative bg-black'>
            <article className='mx-auto max-w-7xl sm:py-32 pt-10 pb-10 px-5 relative z-1 flex items-center'>
              <div className=''>
                <h1 className='text-2xl sm:text-4xl sm:leading-normal font-bold leading-normal w-full'>
                  <span className='text-xl block text-white mb-10'>창꼬 트레이너를 소개합니다.</span>
                </h1>  
                <div>
                  <div className='sm:grid sm:grid-cols-2 sm:gap-6 space-y-6 sm:space-y-0'> 
                    <div>
                        <div><img src={Trainer01} className='w-full rounded' alt='트레이너1'/></div>
                    </div>
                    <div>
                        <div><img src={Trainer02} className='w-full rounded' alt='트레이너2'/></div>
                    </div>
                    <div>
                        <div><img src={Trainer03} className='w-full rounded' alt='트레이너3'/></div>
                    </div>
                    <div>
                        <div><img src={Trainer04} className='w-full rounded' alt='트레이너4'/></div>
                    </div>
                    <div>
                        <div><img src={Trainer05} className='w-full rounded' alt='트레이너5'/></div>
                    </div>
                    <div>
                        <div><img src={Trainer06} className='w-full rounded' alt='트레이너6'/></div>
                    </div>
                    <div>
                        <div><img src={Trainer07} className='w-full rounded' alt='트레이너7'/></div>
                    </div>
                    <div>
                        <div><img src={Trainer08} className='w-full rounded' alt='트레이너8'/></div>
                    </div>
                    <div>
                        <div><img src={Trainer09} className='w-full rounded' alt='트레이너9'/></div>
                    </div>
                    <div>
                        <div><img src={Trainer10} className='w-full rounded' alt='트레이너10'/></div>
                    </div>
                    <div>
                        <div><img src={Trainer11} className='w-full rounded' alt='트레이너11'/></div>
                    </div>
                    <div>
                        <div><img src={Trainer12} className='w-full rounded' alt='트레이너12'/></div>
                    </div>
                    <div>
                        <div><img src={Trainer13} className='w-full rounded' alt='트레이너13'/></div>
                    </div>
                    <div>
                        <div><img src={Trainer14} className='w-full rounded' alt='트레이너14'/></div>
                    </div>
                    <div>
                        <div><img src={Trainer15} className='w-full rounded' alt='트레이너15'/></div>
                    </div>
                    <div>
                        <div><img src={Trainer16} className='w-full rounded' alt='트레이너16'/></div>
                    </div> 
                  </div>
                </div>
              </div>  
            </article>
            {/* <img  src={Bg} className='h-screen absolute inset-0 w-full ' alt='Bg' />  */}
          </section>
          <section className='text-white relative before overflow-hidden'>
            <article className='mx-auto sm:h-screen max-w-7xl sm:py-32 pt-10 pb-28 px-5 relative z-1 flex items-center'>
              <div className=''>
                <h1 className='text-2xl sm:text-4xl sm:leading-normal font-bold leading-normal w-full'>
                  <span className='text-xl block text-Cgcolor'>창꼬실용음악학원</span>
                </h1>  
                <h2 className='text-xl sm:text-2xl sm:leading-normal font-bold leading-normal w-full my-10'>
                  <span className='block'>상담문의는 <span className='text-Cgcolor'>카카오톡 채널</span>에서만 가능합니다.</span>
                </h2>  
                <h2 className='mt-5 w-full'>
                  <span className='block'></span>
                  <span className='block'></span>
                </h2>
                <div className='w-full mt-10'>
                  <a href="https://pf.kakao.com/_QxbxmRG" target='_blank' className='bg-Cgcolor text-sm font-bold rounded-lg py-3 px-4 inline-block '>상담문의</a>
                </div>
              </div>  
            </article>
            <img  src={Bottm_BG} className='sm:h-screen absolute inset-0 w-full opacity-0 sm:opacity-100' alt='Bg1' /> 
            <img  src={Bg_mo2} className='absolute inset-0 w-full opacity-100 sm:opacity-0' alt='Bg1_1' /> 
          </section>
          {/* 참가신청버튼 Start */}
          {/* span 부분 개발로 카운트 설정 부탁합니다. + 모집합니다 하단 h1 동일 */}
          <section className='fixed_wrap md:bottom-3 bottom-0 z-20'>
            <div className='md:flex fixed_bt box-border md:px-5 md:py-5 rounded-lg'>
              {/* <h1 className='flex justify-center  text-white text-center text-sm bg-black md:opacity-100 opacity-70 rounded-lg py-1 px-1'>
                  <span className='block flex'><b className='md:block hidden mr-2'>접수까지</b>남은 시간</span>
                  <span className='pl-2 text-Cgcolor'>
                      {days < 10 ? `0${days}` : days}일 {hours < 10 ? `0${hours}` : hours}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                  </span>
              </h1> */}
              {/* <Link to="/Participate" className='bg-Cgcolor block text-center rounded-lg flex-ac-jc py-1 px-1 md:py-2 md:px-2'>상담 신청</Link> */}
              <a href="https://pf.kakao.com/_QxbxmRG" target='_blank' className='bg-Cgcolor block text-center rounded-lg flex-ac-jc py-3 px-1 md:py-2 md:px-2'>상담문의</a>
            </div>
          </section>
          {/* 참가신청버튼 End */}
        </div> 
        <Footer/>
      </div> 
      
    );
  }
    
    export default Class;
    
  